// export const APIURL     = 'https://demo.unboxschool.in/backend/api';
// export const HOMEPAGE   = 'https://demo.unboxschool.in/';
// export const START_URL  = 'https://demo.unboxschool.in/';
// export const ASSETURL   = 'https://demo.unboxschool.in/backend/';
// export const APPNAME    = 'Unbox School'

// export const APIURL      = 'https://gurukulacademy.unboxschool.in/backend/api';
// export const HOMEPAGE    = 'https://gurukulacademy.unboxschool.in/';
// export const START_URL   = 'https://gurukulacademy.unboxschool.in/';
// export const ASSETURL    = 'https://gurukulacademy.unboxschool.in/backend/';
// export const APPNAME     = 'Gurukul Academy'

// export const APIURL      = 'https://brightfuture.unboxschool.in/backend/api';
// export const HOMEPAGE    = 'https://brightfuture.unboxschool.in/';
// export const START_URL   = 'https://brightfuture.unboxschool.in/';
// export const ASSETURL    = 'https://brightfuture.unboxschool.in/backend/';
// export const APPNAME     = 'Bright Future'


// export const APIURL      = 'https://brightriders.unboxschool.in/backend/api';
// export const HOMEPAGE    = 'https://brightriders.unboxschool.in/';
// export const START_URL   = 'https://brightriders.unboxschool.in/';
// export const ASSETURL    = 'https://brightriders.unboxschool.in/backend/';
// export const APPNAME     = 'Bright Riders'

export const APIURL      = 'https://ccs.unboxschool.in/backend/api';
export const HOMEPAGE    = 'https://ccs.unboxschool.in/';
export const START_URL   = 'https://ccs.unboxschool.in/';
export const ASSETURL    = 'https://ccs.unboxschool.in/backend/';
export const APPNAME     = 'Chakia Central School'

